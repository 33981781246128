  class LocalStorageManager {
  /** 첫 진입시 localStorage 초기화 */
  init() {
    if (this.getVersion() !== process.env.VUE_APP_VERSION) {
      this.setVersion(process.env.VUE_APP_VERSION);
      
      localStorage.removeItem('defaultTbpPartList');
    }
  }

  /** 사용자 로그아웃시 localStorage 초기화 */
  logout() {
    localStorage.removeItem('userAuth');
    localStorage.removeItem('userData');
    localStorage.removeItem('defaultTbpPartList');
    localStorage.removeItem('doctorSeq');
    localStorage.removeItem('clinicSeq');
    localStorage.removeItem('profileImgUrl');
    localStorage.removeItem('tbpRegType');
    localStorage.removeItem('patientSeq');
    localStorage.removeItem('theme');
    localStorage.removeItem('expiryDate');
    localStorage.removeItem('userLocale');
    localStorage.removeItem('spotList');
    localStorage.removeItem('email');
    localStorage.removeItem('tbpRegType');
    localStorage.removeItem('tbpPartGroupList');
    localStorage.removeItem('spot');
  }

  setVersion(value) {
    localStorage.setItem('version', value);
  }
  getVersion() {
    return localStorage.getItem('version');
  }

  setTheme(theme) {
    localStorage.setItem('theme', theme);
  }
  getTheme() {
    return localStorage.getItem('theme');
  }

  setUserAuth(value) {
    localStorage.setItem('userAuth', value);
  }
  getUserAuth() {
    return localStorage.getItem('userAuth');
  }

  setUserData(value) {
    localStorage.setItem('userData', value);
  }
  getUserData() {
    return localStorage.getItem('userData');
  }

  setExpiryDate(value) {
    localStorage.setItem('expiryDate', value);
  }
  getExpiryDate() {
    return localStorage.getItem('expiryDate');
  }

  setUserLocale(value) {
    localStorage.setItem('userLocale', value);
  }
  getUserLocale() {
    return localStorage.getItem('userLocale');
  }

  setSpotList(value) {
    localStorage.setItem('spotList', value);
  }
  getSpotList() {
    return localStorage.getItem('spotList');
  }

  setEmail(value) {
    localStorage.setItem('email', value);
  }
  getEmail() {
    return localStorage.getItem('email');
  }

  setTbpRegType(value) {
    localStorage.setItem('tbpRegType', value);
  }
  getTbpRegType() {
    return localStorage.getItem('tbpRegType');
  }

  setTbpPartGroupList(value) {
    localStorage.setItem('tbpPartGroupList', value);
  }
  getTbpPartGroupList() {
    return localStorage.getItem('tbpPartGroupList');
  }

  setDoctorSeq(value) {
    localStorage.setItem('doctorSeq', value);
  }
  getDoctorSeq() {
    return localStorage.getItem('doctorSeq');
  }

  setClinicSeq(value) {
    localStorage.setItem('clinicSeq', value);
  }
  getClinicSeq() {
    return localStorage.getItem('clinicSeq');
  }

  setProfileImgUrl(value) {
    localStorage.setItem('profileImgUrl', value);
  }
  getProfileImgUrl() {
    return localStorage.getItem('profileImgUrl');
  }

  setPatientSeq(value) {
    localStorage.setItem('patientSeq', value);
  }
  getPatientSeq() {
    return localStorage.getItem('patientSeq');
  }

  setSpot(value) {
    localStorage.setItem('spot', value);
  }
  getSpot() {
    return localStorage.getItem('spot');
  }

  setDefaultTbpPartList(value) {
    localStorage.setItem('defaultTbpPartList', value);
  }
  getDefaultTbpPartList() {
    return localStorage.getItem('defaultTbpPartList');
  }

}

export const localStorageManager = new LocalStorageManager();